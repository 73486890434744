.center {
    margin: auto;
    width: 80%;
    /*border: 3px solid green;*/
    padding: 10px;
}
.logo{
    margin: auto;
    width: 90%;
    /*border: 3px solid green;*/
    padding: 10px;
}
.content{
    text-align: center;
}
.p{
    text-align: justify;
}

.btn{
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    /*border: 3px solid green;*/
    padding: 10px;
}