.center {
    margin: auto;
    width: 80%;
    /*border: 3px solid green;*/
    padding: 10px;
}
.logo{
    margin: auto;
    width: 90%;
    /*border: 3px solid green;*/
    padding: 10px;
}
.donate {
    margin: auto;

    width:80% ;
}
@media (max-width: 500px) {

    .donate {
        padding: 0.8em 0.3em !important;
        display: inline-block;
    }
}
.btn{
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    /*border: 3px solid green;*/
    padding: 10px;
}